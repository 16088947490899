<template>
  <v-row class="pa-5 pt-0">
    <v-col class="pl-1" cols="12">
      <v-btn class="pl-0" text @click="$router.go(-1)">
        <v-icon class="pr-1">mdi-chevron-left</v-icon>
        Voltar
      </v-btn>
    </v-col>
    <v-col cols="12" class="text-h6 black--text pb-0">
      Detalhes da solicitação
    </v-col>
    <v-col cols="12" class="subtitle-2 grey--text pt-0">
      Detalhes da solicitação enviada
    </v-col>
    <v-col cols="12">
      <v-form ref="formServiceRequest" v-model="validForm" lazy-validation>
        <v-row>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <v-row>
              <v-col cols="12"> Assunto: </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  :items="subjects"
                  :disabled="$route.params.id ? true : false"
                  v-model="request.subject"
                  :rules="[(v) => !!v || 'Assunto é obrigatório']"
                  outlined
                  dense
                  :placeholder="$route.params.id ? '' : 'Selecione'"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <v-row>
              <v-col cols="12"> Prioridade: </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  :items="priorities"
                  :disabled="$route.params.id ? true : false"
                  v-model="request.priority"
                  :rules="[(v) => !!v || 'Prioridade é obrigatório']"
                  outlined
                  dense
                  :placeholder="$route.params.id ? '' : 'Selecione'"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-row>
              <v-col cols="12"> Descrição: </v-col>
              <v-col cols="12" class="pt-0">
                <v-textarea
                  :disabled="$route.params.id ? true : false"
                  v-model="request.description"
                  :rules="[(v) => !!v || 'Descrição é obrigatório']"
                  outlined
                  dense
                  :placeholder="
                    $route.params.id ? '' : 'Descreva a necessidade do serviço'
                  "
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="10" class="pt-0 pb-0">
            <v-row class="align-start">
              <!-- <v-col cols="12" :sm="$route.params.id ? 4 : 3" class="pb-0">
                <v-file-input
                  v-model="request.file"
                  class="white--text"
                  dense
                  prepend-icon=""
                  prepend-inner-icon="mdi-download"
                  outlined
                  :disabled="$route.params.id ? true : false"
                  :placeholder="
                    $route.params.id
                      ? !request.file
                        ? 'Nenhum arquivo anexado'
                        : request.file.name
                      : 'Anexar arquivo'
                  "
                ></v-file-input>
                <a :href="request.file && request.file.url">{{request.file && request.file.name}}</a>
              </v-col> -->
              <!-- <v-col
                v-if="request.file && $route.params.id"
                cols="12"
                sm="8"
                :class="{ 'pt-1': request.file }"
              >
                <a
                  :href="request.file.url"
                  v-text="request.file.name"
                  @click.prevent="
                    downloadItem(request.file.signed_id, request.file.name)
                  "
                />
              </v-col> -->
            </v-row>
          </v-col>
          <v-col v-if="!$route.params.id" cols="12" sm="2">
            <v-btn
              @click="onCreateRequest"
              :disabled="!validForm"
              :loading="loading"
              width="100%"
              class="primary"
            >
              Enviar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-col cols="12" v-if="$route.params.id" class="text-h6 black--text">
      Resposta da solicitação
    </v-col>
    <v-col cols="12" v-if="$route.params.id">
      <v-textarea
        outlined
        dense
        disabled
        placeholder="Sem resposta por enquanto."
        v-model="request.answer.answer"
      >
      </v-textarea>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    validForm: true,
    request: {
      subject: null,
      description: null,
      priority: null,
      answer: {
        answer: null,
      },
      file: null,
    },
    subjects: [
      "Dashboard",
      "Alertas",
      "Perfil do paciente",
      "Novo formulário de anamnese",
      "Outros",
    ],
    priorities: ["Baixa", "Media", "Alta"],
    teste: null,
    loading: false
  }),
  mounted() {
    if (this.$route.params.id) {
      if (this.$route.params.data != undefined)
        this.request = this.$route.params.data;
      if (this.$route.params.data == undefined) this.showRequest();
    }
  },
  methods: {
    async showRequest() {
      try {
        const response = await this.$axios(
          "service_requests/" + this.$route.params.id
        );

        if (response.data)
          this.request = {
            id: response.data.id,
            date: this.formatLastUpdate(response.data.updated_at),
            status: this.setStatus(response.data.status),
            priority: response.data.priority,
            description: response.data.description,
            subject: response.data.subject,
            // file: response.data.file ? response.data.file : null,
            answer: response.data.service_request_response || {},
          };

      } catch (error) {
        const msg = error.response.error
        console.log("Get request error: ", error);
        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar a solicitação." + msg,
        });
      }
    },
    async onCreateRequest() {
      try {
        this.loading = true;
        if (!this.$refs.formServiceRequest.validate()) {
          await this.$swal({
            position: "center",
            showConfirmButton: true,
            icon: "error",
            title: "MIS",
            text: "Por favor, preencha os campos obrigatórios.",
          });

          return;
        }

        this.request = Object.assign(
          { doctor_id: this.currentUser.id },
          this.request
        );

        // if (this.request.answer) delete this.request.answer;

        // const formData = new FormData();

        // Object.entries(this.request).forEach(([key, value]) => {
        //   formData.append(`service_request[${key}]`, value);
        // });

        // formData.delete("answer");

        // const headers = { "Content-Type": "multipart/form-formData" };

        await this.$axios.post("service_requests", this.request);

        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Solicitação enviada com sucesso.",
        });

        this.$router.push({ name: "Services" });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Create request error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar criar a solicitação de requisição.",
        });
      }
    },
    setStatus(value) {
      const status = {
        opened: {
          text: "Em aberto",
          color: "#feda15",
        },
        under_analysis: {
          text: "Em análise",
          color: "#096CC8",
        },
        concluded: {
          text: "Concluído",
          color: "#00AD45",
        },
        closed: {
          text: "Cancelado",
          color: "#CF4444",
        },
      };

      return (
        status[value] || {
          text: "Em aberto",
          color: "#feda15",
        }
      );
    },
    formatLastUpdate(date) {
      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    downloadItem(signed_id, file_name) {
      this.$axios
        .get(`download/${signed_id}/${file_name}`, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", file_name);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
  },
};
</script>

<style></style>
